var routeConfigList = [{
	id: '4a482197-41df-4186-b2f1-dffac87d44c5',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PB_CRImportDrawer',
	onMenu: 'Y',
	routeName: 'PB_CRImportDrawer',
	urlRoute: '/PB_CRImportDrawer',
	templateName: './templates/PB_CRImportDrawer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '305939a2-69a7-469a-b0d5-1ec53e184fdd',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PB_bellCurveChart',
	onMenu: 'Y',
	routeName: 'PB_bellCurveChart',
	urlRoute: '/PB_bellCurveChart',
	templateName: './templates/PB_bellCurveChart.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '08e6bb33-1e0b-4fb3-930b-64d3c05e2d4c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_appraisal_question',
	onMenu: 'Y',
	routeName: 'peer_appraisal_question',
	urlRoute: '/peer_appraisal_question',
	templateName: './templates/peer_appraisal_question.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6eaff747-763e-4e9f-8d6d-828e71af94b0',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KLKPL_dashboard',
	onMenu: 'Y',
	routeName: 'KLKPL_dashboard',
	urlRoute: '/KLKPL_dashboard',
	templateName: './templates/KLKPL_dashboard.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '584fdb2b-4c3c-4715-ad78-7a288ae6ef26',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_counsel',
	onMenu: 'Y',
	routeName: 'kpi_counsel',
	urlRoute: '/kpi_counsel',
	templateName: './templates/kpi_counsel.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'aecd6adc-9cad-45a3-a2ac-d3b159d1bbca',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_excees_claim_hr',
	onMenu: 'Y',
	routeName: 'rl5_excees_claim_hr',
	urlRoute: '/rl5_excees_claim_hr',
	templateName: './templates/rl5_excees_claim_hr.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6343263c-0bd8-40e9-bdae-7a80143b3be3',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Manage_Org_Chart_R2',
	onMenu: 'Y',
	routeName: 'Manage_Org_Chart_R2',
	urlRoute: '/Manage_Org_Chart_R2',
	templateName: './templates/Manage_Org_Chart_R2.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '56f08201-390a-4d7c-bdf3-1c172ca54dfe',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_06_topic_perfor',
	onMenu: 'Y',
	routeName: 'RM004_06_topic_perfor',
	urlRoute: '/RM004_06_topic_perfor',
	templateName: './templates/RM004_06_topic_perfor.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '8c4a0679-5036-4b53-bfb0-cec10a5aac6f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_time_adjust_request',
	onMenu: 'Y',
	routeName: 'T2_time_adjust_request',
	urlRoute: '/T2_time_adjust_request',
	templateName: './templates/T2_time_adjust_request.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '7e978fcc-3d4a-46a3-881f-36c512807e13',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Org_Manage_Chart',
	onMenu: 'Y',
	routeName: 'Org_Manage_Chart',
	urlRoute: '/Org_Manage_Chart',
	templateName: './templates/Org_Manage_Chart.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '03bcd137-8be8-4250-a7af-f26b69d24d0d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_Personal_Info',
	onMenu: 'Y',
	routeName: 'RM002_02_Personal_Info',
	urlRoute: '/RM002_02_Personal_Info',
	templateName: './templates/RM002_02_Personal_Info.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd185396c-e948-450c-82f9-7bb84a6c401f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_cascade',
	onMenu: 'Y',
	routeName: 'kpi_cascade',
	urlRoute: '/kpi_cascade',
	templateName: './templates/kpi_cascade.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'eed833f4-03f0-4f27-8b6a-bb25002e5489',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_07',
	onMenu: 'Y',
	routeName: 'RM004_07',
	urlRoute: '/RM004_07',
	templateName: './templates/RM004_07.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e4e524b1-6409-4903-8d28-8dbd672c47eb',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_compendancy',
	onMenu: 'Y',
	routeName: 'RL5_compendancy',
	urlRoute: '/RL5_compendancy',
	templateName: './templates/RL5_compendancy.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '2eeb8ad7-cb0c-486e-aa79-0669a89881d8',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_other_benefits',
	onMenu: 'Y',
	routeName: 'rl5_other_benefits',
	urlRoute: '/rl5_other_benefits',
	templateName: './templates/rl5_other_benefits.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Other Benefits',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '89b87c9a-2d0d-439d-b197-093ed597a00a',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_review_manage',
	onMenu: 'Y',
	routeName: 'peer_review_manage',
	urlRoute: '/peer_review_manage',
	templateName: './templates/peer_review_manage.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e453dd35-2170-4ad0-bee0-875a192b967a',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_reviewRatingDrawer',
	onMenu: 'Y',
	routeName: 'kpi_reviewRatingDrawer',
	urlRoute: '/kpi_reviewRatingDrawer',
	templateName: './templates/kpi_reviewRatingDrawer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '81a1066c-464a-4fe3-8ed7-afc25b4e5c50',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PB_compare',
	onMenu: 'Y',
	routeName: 'PB_compare',
	urlRoute: '/PB_compare',
	templateName: './templates/PB_compare.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'bbc03175-3369-4e00-8159-94366f575235',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_08_assessment',
	onMenu: 'Y',
	routeName: 'RM004_08_assessment',
	urlRoute: '/RM004_08_assessment',
	templateName: './templates/RM004_08_assessment.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '382081d8-3d60-4010-aa52-775bdce54382',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'select_member_talent_pool',
	onMenu: 'Y',
	routeName: 'select_member_talent_pool',
	urlRoute: '/select_member_talent_pool',
	templateName: './templates/select_member_talent_pool.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0fc760ab-f1a9-406d-8025-b9febd81c7d2',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_provident_fund',
	onMenu: 'Y',
	routeName: 'T2_provident_fund',
	urlRoute: '/T2_provident_fund',
	templateName: './templates/T2_provident_fund.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '075c106c-e4b5-44de-b16b-477b85df54ce',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_service_menu',
	onMenu: 'Y',
	routeName: 'rl5_service_menu',
	urlRoute: '/rl5_service_menu',
	templateName: './templates/rl5_service_menu.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Service Center',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a1b4d841-c676-42c9-b075-0066780d9f40',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_review_add_detail',
	onMenu: 'Y',
	routeName: 'peer_review_add_detail',
	urlRoute: '/peer_review_add_detail',
	templateName: './templates/peer_review_add_detail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'KPI Setting',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '2edd77f7-9fad-4668-b228-491a3e14da7e',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_talent',
	onMenu: 'Y',
	routeName: 'rl5_talent',
	urlRoute: '/rl5_talent',
	templateName: './templates/rl5_talent.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0f7a95d8-4add-400a-ac18-6ce1afa4aaac',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'training_request',
	onMenu: 'Y',
	routeName: 'training_request',
	urlRoute: '/training_request',
	templateName: './templates/training_request.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '652e9829-d9d3-4381-a499-eed2a4292371',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'global_function',
	onMenu: 'Y',
	routeName: 'global_function',
	urlRoute: '/global_function',
	templateName: './templates/global_function.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '8836bece-60de-4ec0-8d30-97a985a96fd7',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'GSB',
	onMenu: 'Y',
	routeName: 'GSB',
	urlRoute: '/GSB',
	templateName: './templates/GSB.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '912121f2-a1ca-45ec-bf6f-5f0c1b7089a0',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_T1_Resign_resign_requ',
	onMenu: 'Y',
	routeName: 'RL5_T1_Resign_resign_requ',
	urlRoute: '/RL5_T1_Resign_resign_requ',
	templateName: './templates/RL5_T1_Resign_resign_requ.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '22c28304-f9e2-4868-b5c1-c651c07dbe47',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'talent_progress',
	onMenu: 'Y',
	routeName: 'talent_progress',
	urlRoute: '/talent_progress',
	templateName: './templates/talent_progress.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd48584bd-5f8b-4276-aa9e-f90737f09239',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'manpower_1',
	onMenu: 'Y',
	routeName: 'manpower_1',
	urlRoute: '/manpower_1',
	templateName: './templates/manpower_1.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3cebd266-acd0-42a1-bbbd-41d078590f1a',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'talent_header',
	onMenu: 'Y',
	routeName: 'talent_header',
	urlRoute: '/talent_header',
	templateName: './templates/talent_header.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '48be8d47-7829-444d-a109-2de7d9cd7791',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_T1_Time_Adjust_req',
	onMenu: 'Y',
	routeName: 'RL5_T1_Time_Adjust_req',
	urlRoute: '/RL5_T1_Time_Adjust_req',
	templateName: './templates/RL5_T1_Time_Adjust_req.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '976e0fcc-6bcd-4081-af53-353a157f8f68',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_masterSetupDrawer',
	onMenu: 'Y',
	routeName: 'kpi_masterSetupDrawer',
	urlRoute: '/kpi_masterSetupDrawer',
	templateName: './templates/kpi_masterSetupDrawer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '9cf89c1b-feb7-41ce-8a77-d6b7eff00d47',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_other_benefits_v2',
	onMenu: 'Y',
	routeName: 'rl5_other_benefits_v2',
	urlRoute: '/rl5_other_benefits_v2',
	templateName: './templates/rl5_other_benefits_v2.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Other Benefits',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a142abce-0e90-4497-9f58-f76ee43d9e37',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KPI_Appraisal',
	onMenu: 'Y',
	routeName: 'KPI_Appraisal',
	urlRoute: '/KPI_Appraisal',
	templateName: './templates/KPI_Appraisal.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'f1c75ebe-41c9-481e-aedb-7c72157d961a',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Job_Opening',
	onMenu: 'Y',
	routeName: 'Job_Opening',
	urlRoute: '/Job_Opening',
	templateName: './templates/Job_Opening.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1b2d7e33-4f67-4294-964f-23a9da05544d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_selfevaluate',
	onMenu: 'Y',
	routeName: 'kpi_selfevaluate',
	urlRoute: '/kpi_selfevaluate',
	templateName: './templates/kpi_selfevaluate.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6780e508-a468-4261-9664-73cf13814b10',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'performance_management',
	onMenu: 'Y',
	routeName: 'performance_management',
	urlRoute: '/performance_management',
	templateName: './templates/performance_management.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '76249211-939d-4aa3-8362-0539b5f745eb',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_04_Reject_Reason',
	onMenu: 'Y',
	routeName: 'RM004_04_Reject_Reason',
	urlRoute: '/RM004_04_Reject_Reason',
	templateName: './templates/RM004_04_Reject_Reason.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1050d1eb-3fc4-4591-92ea-ac9d15a7ad24',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Career',
	onMenu: 'Y',
	routeName: 'Career',
	urlRoute: '/Career',
	templateName: './templates/Career.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6aee77a1-2a83-4fdc-be85-661f61cf0081',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KP005_01',
	onMenu: 'Y',
	routeName: 'KP005_01',
	urlRoute: '/KP005_01',
	templateName: './templates/KP005_01.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1f82c0dd-d5af-4e0b-919a-d6ec6ab40b98',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Learning',
	onMenu: 'Y',
	routeName: 'Learning',
	urlRoute: '/Learning',
	templateName: './templates/Learning.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd8114fa3-e8a7-4e6d-8fd1-abaa73170db4',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_service_req_menu',
	onMenu: 'Y',
	routeName: 'RL5_service_req_menu',
	urlRoute: '/RL5_service_req_menu',
	templateName: './templates/RL5_service_req_menu.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '716c0c49-7685-459d-8357-e8bb8b658cd1',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_06_comment',
	onMenu: 'Y',
	routeName: 'RM004_06_comment',
	urlRoute: '/RM004_06_comment',
	templateName: './templates/RM004_06_comment.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'aa9ce085-0160-4a4f-a7bf-9dc3b739c3a1',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_corporate-Copy(1)',
	onMenu: 'Y',
	routeName: 'kpi_corporate-Copy(1)',
	urlRoute: '/kpi_corporate-Copy(1)',
	templateName: './templates/kpi_corporate-Copy(1).html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '57e98522-f9fe-4688-bc65-95041b743aae',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_education',
	onMenu: 'Y',
	routeName: 'RM002_02_education',
	urlRoute: '/RM002_02_education',
	templateName: './templates/RM002_02_education.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '269cb6d1-6592-4151-8e82-cb92e0388542',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Final_KPI_Appraisal',
	onMenu: 'Y',
	routeName: 'Final_KPI_Appraisal',
	urlRoute: '/Final_KPI_Appraisal',
	templateName: './templates/Final_KPI_Appraisal.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '05f351cd-9eae-4e2d-99d4-0cbc6d2ea286',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'review_setting_approve',
	onMenu: 'Y',
	routeName: 'review_setting_approve',
	urlRoute: '/review_setting_approve',
	templateName: './templates/review_setting_approve.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a6135770-fdd5-45d4-861d-f751a3ac611e',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'find_performance_baseline',
	onMenu: 'Y',
	routeName: 'find_performance_baseline',
	urlRoute: '/find_performance_baseline',
	templateName: './templates/find_performance_baseline.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '06ef3e1a-951a-4a3f-bab7-79a601ffea4b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'profile',
	onMenu: 'Y',
	routeName: 'profile',
	urlRoute: '/profile',
	templateName: './templates/profile.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'bf4d24c4-8f5e-45ac-a50f-97c75bde0ec9',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_T1_SwapShift_swap_req',
	onMenu: 'Y',
	routeName: 'RL5_T1_SwapShift_swap_req',
	urlRoute: '/rl5_t1_swapshift_swap_req',
	templateName: './templates/RL5_T1_SwapShift_swap_req.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e867addf-8391-4479-bbac-15f8e798a764',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_06_user_info',
	onMenu: 'Y',
	routeName: 'RM004_06_user_info',
	urlRoute: '/RM004_06_user_info',
	templateName: './templates/RM004_06_user_info.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '32bb9196-da43-4f10-a891-3c6973618e1c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Login_ADFS',
	onMenu: 'Y',
	routeName: 'Login_ADFS',
	urlRoute: '/Login_ADFS',
	templateName: './templates/Login_ADFS.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Login',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '2a233145-4935-47aa-ae5c-b4463f26f21f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Contact',
	onMenu: 'Y',
	routeName: 'Contact',
	urlRoute: '/contact',
	templateName: './templates/Contact.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '62ab9321-216f-4bf2-8b42-802a47fd34de',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_settingAdd',
	onMenu: 'Y',
	routeName: 'kpi_settingAdd',
	urlRoute: '/kpi_settingAdd',
	templateName: './templates/kpi_settingAdd.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '04bdb6d8-2527-410d-a24e-e83441a19f23',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_corporate',
	onMenu: 'Y',
	routeName: 'kpi_corporate',
	urlRoute: '/kpi_corporate',
	templateName: './templates/kpi_corporate.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0fe839d2-560c-4957-a67b-f95b53bdae0c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Job_Opening',
	onMenu: 'Y',
	routeName: 'Job_Opening',
	urlRoute: '/Job_Opening',
	templateName: './templates/Job_Opening.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '9821abcf-d326-4828-ae81-c73701e599e7',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'competency_setting',
	onMenu: 'Y',
	routeName: 'competency_setting',
	urlRoute: '/competency_setting',
	templateName: './templates/competency_setting.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Competency Setting',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '096ada96-8b26-4185-bff3-583378155225',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'manpower_3',
	onMenu: 'Y',
	routeName: 'manpower_3',
	urlRoute: '/manpower_3',
	templateName: './templates/manpower_3.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '27e08b4e-c407-467c-a64b-5c28ebdedeb3',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'program',
	onMenu: 'Y',
	routeName: 'program',
	urlRoute: '/program',
	templateName: './templates/program.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'fc612270-76f5-4963-a1ec-4aeb5d8f72dd',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'ess_new',
	onMenu: 'Y',
	routeName: 'ess_new',
	urlRoute: '/ess_new',
	templateName: './templates/ess_new.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'b1ee3eb5-1df1-4d5e-853b-68e8923d61f2',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_teamBenefit',
	onMenu: 'Y',
	routeName: 'rl5_teamBenefit',
	urlRoute: '/rl5_teamBenefit',
	templateName: './templates/rl5_teamBenefit.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'cc75e36b-5f79-4e6c-9755-2d3419f345b2',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'LogoutCAS',
	onMenu: 'Y',
	routeName: 'LogoutCAS',
	urlRoute: '/LogoutCAS',
	templateName: './templates/LogoutCAS.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3655bc17-29e7-4ae9-955d-e322dbcd63a2',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'training_roadmap',
	onMenu: 'Y',
	routeName: 'training_roadmap',
	urlRoute: '/training_roadmap',
	templateName: './templates/training_roadmap.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'eb0d8312-7e3c-4d75-bc10-dcae7c98988b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'bidCourses_section',
	onMenu: 'Y',
	routeName: 'bidCourses_section',
	urlRoute: '/bidCourses_section',
	templateName: './templates/bidCourses_section.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3b5e8747-1c5e-4d4a-bdbc-9c5126486296',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_manageImportDrawer',
	onMenu: 'Y',
	routeName: 'kpi_manageImportDrawer',
	urlRoute: '/kpi_manageImportDrawer',
	templateName: './templates/kpi_manageImportDrawer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '03dbe0cf-ea1d-4721-a98c-f4205a9d9a09',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_empEvalProfile',
	onMenu: 'Y',
	routeName: 'kpi_empEvalProfile',
	urlRoute: '/kpi_empEvalProfile',
	templateName: './templates/kpi_empEvalProfile.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '5ff8d435-37d5-4637-b95d-b137c88863a6',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_review_add',
	onMenu: 'Y',
	routeName: 'peer_review_add',
	urlRoute: '/peer_review_add',
	templateName: './templates/peer_review_add.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'b3c01d85-32e0-49b8-a0ec-d135f77525f7',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'competencyChallenge',
	onMenu: 'Y',
	routeName: 'competencyChallenge',
	urlRoute: '/competencyChallenge',
	templateName: './templates/competencyChallenge.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Competency Appraisal (Self Evaluation)',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '25784026-3210-4ab5-8bcd-48b65f6d637e',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5SwapShift',
	onMenu: 'Y',
	routeName: 'RL5SwapShift',
	urlRoute: '/RL5SwapShift',
	templateName: './templates/RL5SwapShift.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0bbad140-e920-45c3-a9c3-cc2a529cd9e7',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_06_attach_file',
	onMenu: 'Y',
	routeName: 'RM004_06_attach_file',
	urlRoute: '/RM004_06_attach_file',
	templateName: './templates/RM004_06_attach_file.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd429855e-50d3-405c-bfd0-caffb7274881',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'create_program',
	onMenu: 'Y',
	routeName: 'create_program',
	urlRoute: '/create_program',
	templateName: './templates/create_program.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a4f88c07-ebb3-44a3-9a96-b6d62e10d1be',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KPI_Employees_Review',
	onMenu: 'Y',
	routeName: 'KPI_Employees_Review',
	urlRoute: '/KPI_Employees_Review',
	templateName: './templates/KPI_Employees_Review.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '59fbabe2-0753-47be-a7d1-75264b33d79e',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'career_careerpath',
	onMenu: 'Y',
	routeName: 'career_careerpath',
	urlRoute: '/career_careerpath',
	templateName: './templates/career_careerpath.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '170ddd09-90c1-44c9-aca3-3406eeae0823',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_setting',
	onMenu: 'Y',
	routeName: 'kpi_setting',
	urlRoute: '/kpi_setting',
	templateName: './templates/kpi_setting.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'KPI Setting',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'ed834d1e-850d-41fa-b0f8-d520bf52a1b7',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'training_request_hr_view',
	onMenu: 'Y',
	routeName: 'training_request_hr_view',
	urlRoute: '/training_request_hr_view',
	templateName: './templates/training_request_hr_view.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3509686a-0df4-4745-94d3-8401a1893851',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_navbar',
	onMenu: 'Y',
	routeName: 'rl5_navbar',
	urlRoute: '/rl5_navbar',
	templateName: './templates/rl5_navbar.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1b996997-721e-44fb-9ce7-c676d5b36843',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_language_skill',
	onMenu: 'Y',
	routeName: 'RM002_02_language_skill',
	urlRoute: '/RM002_02_language_skill',
	templateName: './templates/RM002_02_language_skill.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '88d43b47-1ff3-4f6f-92f1-70205140b166',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PR_dashboard',
	onMenu: 'Y',
	routeName: 'PR_dashboard',
	urlRoute: '/PR_dashboard',
	templateName: './templates/PR_dashboard.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '9e1f474d-5b55-42ce-bcf2-770f6f6d82ba',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_evaluation',
	onMenu: 'Y',
	routeName: 'kpi_evaluation',
	urlRoute: '/kpi_evaluation',
	templateName: './templates/kpi_evaluation.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '4e759b01-e915-44b4-8c90-571f3c0404bd',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_sidebar',
	onMenu: 'Y',
	routeName: 'rl5_sidebar',
	urlRoute: '/rl5_sidebar',
	templateName: './templates/rl5_sidebar.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '750f86b2-9a29-4f76-84ca-0f2dd4f03cdd',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Manage_My_Team',
	onMenu: 'Y',
	routeName: 'Manage_My_Team',
	urlRoute: '/Manage_My_Team',
	templateName: './templates/Manage_My_Team.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3d50027d-921c-4413-b6b8-94a893cb50ab',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PB_compensationReview',
	onMenu: 'Y',
	routeName: 'PB_compensationReview',
	urlRoute: '/PB_compensationReview',
	templateName: './templates/PB_compensationReview.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1a8191a5-56f2-43b0-822b-1c13e8e30e36',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_Report',
	onMenu: 'Y',
	routeName: 'RL5_Report',
	urlRoute: '/RL5_Report',
	templateName: './templates/RL5_Report.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0be35a90-8dbe-4750-ae9b-d3ba085e99f3',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'HCMRL5TimeRecordDaily',
	onMenu: 'Y',
	routeName: 'HCMRL5TimeRecordDaily',
	urlRoute: '/HCMRL5TimeRecordDaily',
	templateName: './templates/HCMRL5TimeRecordDaily.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '84817c41-fbec-4262-86f7-14c4b41fac9b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_06_expected_product',
	onMenu: 'Y',
	routeName: 'RM004_06_expected_product',
	urlRoute: '/RM004_06_expected_product',
	templateName: './templates/RM004_06_expected_product.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '42246c93-8aef-40f3-9a0c-752caec07a87',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_other_benefits_newV2',
	onMenu: 'Y',
	routeName: 'rl5_other_benefits_newV2',
	urlRoute: '/rl5_other_benefits_newV2',
	templateName: './templates/rl5_other_benefits_newV2.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '8f8d9a0f-8f71-4c14-a77d-37af8780c59b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'ess_gsb_new',
	onMenu: 'Y',
	routeName: 'ess_gsb_new',
	urlRoute: '/ess_gsb_new',
	templateName: './templates/ess_gsb_new.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '7c305d2e-8183-4438-9b52-c48165422b22',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'template_function',
	onMenu: 'Y',
	routeName: 'template_function',
	urlRoute: '/template_function',
	templateName: './templates/template_function.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '314aff8e-8600-4217-8a8c-9103a3a29016',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_selfReviewNew',
	onMenu: 'Y',
	routeName: 'kpi_selfReviewNew',
	urlRoute: '/kpi_selfReviewNew',
	templateName: './templates/kpi_selfReviewNew.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '8f02e4e3-2d2a-42e8-b742-0ea31ef312b0',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM001_01_public_relations',
	onMenu: 'Y',
	routeName: 'RM001_01_public_relations',
	urlRoute: '/RM001_01_public_relations',
	templateName: './templates/RM001_01_public_relations.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '50690fed-eb70-430b-bc66-f1ad443611d0',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KLPB_detail',
	onMenu: 'Y',
	routeName: 'KLPB_detail',
	urlRoute: '/KLPB_detail',
	templateName: './templates/KLPB_detail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '440402e3-63cc-4bdb-a58f-3986538fad21',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KP003_01_appraiser',
	onMenu: 'Y',
	routeName: 'KP003_01_appraiser',
	urlRoute: '/KP003_01_appraiser',
	templateName: './templates/KP003_01_appraiser.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'fd9edc50-4213-4808-adbb-a09c2ee80e81',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_01',
	onMenu: 'Y',
	routeName: 'RM002_01',
	urlRoute: '/RM002_01',
	templateName: './templates/RM002_01.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'fb16f5b6-60fe-403c-96cb-4744d4273bc2',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi',
	onMenu: 'Y',
	routeName: 'kpi',
	urlRoute: '/kpi',
	templateName: './templates/kpi.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e6603296-4b09-4e78-9f71-328df84af935',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_emp_information_approve',
	onMenu: 'Y',
	routeName: 'T2_emp_information_approve',
	urlRoute: '/T2_emp_information_approve',
	templateName: './templates/T2_emp_information_approve.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Employee information Approve',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '5439aec9-adec-4f25-a0cc-fb731f4ab287',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_cascade-Copy(1)',
	onMenu: 'Y',
	routeName: 'kpi_cascade-Copy(1)',
	urlRoute: '/kpi_cascade-Copy(1)',
	templateName: './templates/kpi_cascade-Copy(1).html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1718b638-c2da-4eb8-8aa4-209bba6840fe',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PR_monitorManagement',
	onMenu: 'Y',
	routeName: 'PR_monitorManagement',
	urlRoute: '/PR_monitorManagement',
	templateName: './templates/PR_monitorManagement.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0d46f646-dd2d-4eaf-8982-26f067464664',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_managementDashboard',
	onMenu: 'Y',
	routeName: 'kpi_managementDashboard',
	urlRoute: '/kpi_managementDashboard',
	templateName: './templates/kpi_managementDashboard.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '15f34b47-951a-4b4d-b2ab-33bb6570f33c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'competency_settingAdd',
	onMenu: 'Y',
	routeName: 'competency_settingAdd',
	urlRoute: '/competency_settingAdd',
	templateName: './templates/competency_settingAdd.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '9347990e-6eb9-4cc7-a42d-540262df7c9b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'ESS',
	onMenu: 'Y',
	routeName: 'ESS',
	urlRoute: '/ESS',
	templateName: './templates/ESS.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'f1a979af-fb68-4a0a-aa68-1a870613cb33',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'select_member',
	onMenu: 'Y',
	routeName: 'select_member',
	urlRoute: '/select_member',
	templateName: './templates/select_member.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '696cf87d-5b4f-480e-983b-2084c239682c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Manage_Org_Chart',
	onMenu: 'Y',
	routeName: 'Manage_Org_Chart',
	urlRoute: '/Manage_Org_Chart',
	templateName: './templates/Manage_Org_Chart.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '677e8ce1-5eec-493b-8dc2-50032babe984',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'review_setting_page',
	onMenu: 'Y',
	routeName: 'review_setting_page',
	urlRoute: '/review_setting_page',
	templateName: './templates/review_setting_page.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '86aa09b1-c8e4-4797-a896-8384c6bbad56',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_TypeCommentDrawer',
	onMenu: 'Y',
	routeName: 'kpi_TypeCommentDrawer',
	urlRoute: '/kpi_TypeCommentDrawer',
	templateName: './templates/kpi_TypeCommentDrawer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '2499da96-eaca-4bf4-8ed6-791f30238859',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_review_assessor',
	onMenu: 'Y',
	routeName: 'peer_review_assessor',
	urlRoute: '/peer_review_assessor',
	templateName: './templates/peer_review_assessor.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6d406621-5cb8-46f3-a8fe-a53a80c775fe',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Em_Certificate_Approve',
	onMenu: 'Y',
	routeName: 'Em_Certificate_Approve',
	urlRoute: '/Em_Certificate_Approve',
	templateName: './templates/Em_Certificate_Approve.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '4c6413a4-2813-4e41-a22c-8a6891263f58',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'external_training_req',
	onMenu: 'Y',
	routeName: 'external_training_req',
	urlRoute: '/external_training_req',
	templateName: './templates/external_training_req.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '9fae907e-740b-4996-9697-60ef64bd6cac',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_functional-Copy(1)',
	onMenu: 'Y',
	routeName: 'kpi_functional-Copy(1)',
	urlRoute: '/kpi_functional-Copy(1)',
	templateName: './templates/kpi_functional-Copy(1).html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'f0dbe600-aa84-4667-aa33-828b388b16b4',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM001_01',
	onMenu: 'Y',
	routeName: 'RM001_01',
	urlRoute: '/RM001_01',
	templateName: './templates/RM001_01.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a0fda204-4060-4c43-8fa9-14a547cd9a15',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_11',
	onMenu: 'Y',
	routeName: 'RM004_11',
	urlRoute: '/RM004_11',
	templateName: './templates/RM004_11.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'f310d42c-bad8-40a7-99fd-7da3006aaa29',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_review_monitor',
	onMenu: 'Y',
	routeName: 'peer_review_monitor',
	urlRoute: '/peer_review_monitor',
	templateName: './templates/peer_review_monitor.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '89eb56c4-05b1-4656-af75-f1550a2de38d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'performance_baselineAdd',
	onMenu: 'Y',
	routeName: 'performance_baselineAdd',
	urlRoute: '/performance_baselineAdd',
	templateName: './templates/performance_baselineAdd.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3aba2439-2105-4d2d-a0b6-943763aff9a8',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PB_HRSetting',
	onMenu: 'Y',
	routeName: 'PB_HRSetting',
	urlRoute: '/PB_HRSetting',
	templateName: './templates/PB_HRSetting.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '4e564e6a-cac1-466a-9fd3-c583aac81a41',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'EBW00A-02-01_S',
	onMenu: 'Y',
	routeName: 'EBW00A-02-01_S',
	urlRoute: '/EBW00A-02-01_S',
	templateName: './templates/EBW00A-02-01_S.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Claim Benefits request TCG',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '230a9370-c771-4a19-a180-1823f546272e',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_provident_fund_Appr',
	onMenu: 'Y',
	routeName: 'T2_provident_fund_Appr',
	urlRoute: '/T2_provident_fund_Appr',
	templateName: './templates/T2_provident_fund_Appr.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'f571ed2c-4898-4382-82d4-1f049cf56286',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'training_request_hr',
	onMenu: 'Y',
	routeName: 'training_request_hr',
	urlRoute: '/training_request_hr',
	templateName: './templates/training_request_hr.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'dc2031c6-04e9-4614-adc4-34bd4d3dffab',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_time_adjust_history',
	onMenu: 'Y',
	routeName: 'T2_time_adjust_history',
	urlRoute: '/T2_time_adjust_history',
	templateName: './templates/T2_time_adjust_history.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '779b9ed9-30c7-4ee7-b5ff-c5f725eaa0ae',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_topicDetailDrawer',
	onMenu: 'Y',
	routeName: 'kpi_topicDetailDrawer',
	urlRoute: '/kpi_topicDetailDrawer',
	templateName: './templates/kpi_topicDetailDrawer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e7bff3c5-34de-47f5-a329-6c620721db5e',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_job_detail',
	onMenu: 'Y',
	routeName: 'RM002_job_detail',
	urlRoute: '/RM002_job_detail',
	templateName: './templates/RM002_job_detail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '137c8a60-2ad8-4461-84b6-384a6cf31526',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_10_score_summary',
	onMenu: 'Y',
	routeName: 'RM004_10_score_summary',
	urlRoute: '/RM004_10_score_summary',
	templateName: './templates/RM004_10_score_summary.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3f2a974e-30aa-473c-983c-15e13939cf9f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_competency_self',
	onMenu: 'Y',
	routeName: 'rl5_competency_self',
	urlRoute: '/rl5_competency_self',
	templateName: './templates/rl5_competency_self.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Competency Appraisal (Self Evaluation)',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '38322406-934d-4d8e-9629-18372da04618',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_01_job_detail',
	onMenu: 'Y',
	routeName: 'RM002_01_job_detail',
	urlRoute: '/RM002_01_job_detail',
	templateName: './templates/RM002_01_job_detail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '47cc260a-9491-4e91-a1c9-69ad587198f2',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'ESS_GSB',
	onMenu: 'Y',
	routeName: 'ESS_GSB',
	urlRoute: '/ESS_GSB',
	templateName: './templates/ESS_GSB.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'c4e04fb0-32af-4a1b-99b4-2e497022968d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_01_post_job',
	onMenu: 'Y',
	routeName: 'RM002_01_post_job',
	urlRoute: '/RM002_01_post_job',
	templateName: './templates/RM002_01_post_job.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '34a708cb-1302-48ae-83e8-0e0a8510b9bd',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_reviewAppraiser',
	onMenu: 'Y',
	routeName: 'kpi_reviewAppraiser',
	urlRoute: '/kpi_reviewAppraiser',
	templateName: './templates/kpi_reviewAppraiser.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'eb9c8ca2-b4d2-4e16-af2d-530cc3f55a95',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Login',
	onMenu: 'Y',
	routeName: 'Login',
	urlRoute: '/Login',
	templateName: './templates/Login.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'bf515a75-d6d3-41c7-b6d1-a84711969a89',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Benefit',
	onMenu: 'Y',
	routeName: 'Benefit',
	urlRoute: '/Benefit',
	templateName: './templates/Benefit.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '23c5ecdb-811f-4090-9aa9-a859b20d719a',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi',
	onMenu: 'Y',
	routeName: 'kpi',
	urlRoute: '/kpi',
	templateName: './templates/kpi.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '713674a3-e23e-4bf1-b29b-c37b6105bdcb',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'talent_pool',
	onMenu: 'Y',
	routeName: 'talent_pool',
	urlRoute: '/talent_pool',
	templateName: './templates/talent_pool.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '33a8df24-43c3-49e8-a9f3-ef6da6590fed',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_T1_Requis_intern_cre',
	onMenu: 'Y',
	routeName: 'RL5_T1_Requis_intern_cre',
	urlRoute: '/RL5_T1_Requis_intern_cre',
	templateName: './templates/RL5_T1_Requis_intern_cre.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'RL5_T1_Requistion_requis_intern_req_create',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a1386499-d4b4-4510-a7d8-e904b2525b6f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_T1_Requ_intern_detail',
	onMenu: 'Y',
	routeName: 'RL5_T1_Requ_intern_detail',
	urlRoute: '/rl5_t1_requ_intern_detail',
	templateName: './templates/RL5_T1_Requ_intern_detail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '2545ba1b-7f86-478a-9f4f-f22662f79dfe',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Form',
	onMenu: 'Y',
	routeName: 'Form',
	urlRoute: '/Form',
	templateName: './templates/Form.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'c27ef667-7a1c-470b-83c1-7f7e208a63d1',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'bidCourses_detail',
	onMenu: 'Y',
	routeName: 'bidCourses_detail',
	urlRoute: '/bidCourses_detail',
	templateName: './templates/bidCourses_detail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'aa2c6ae2-5937-440c-b23c-812582a397d0',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM001_01_career_path',
	onMenu: 'Y',
	routeName: 'RM001_01_career_path',
	urlRoute: '/RM001_01_career_path',
	templateName: './templates/RM001_01_career_path.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3b84757d-13ba-44a7-8d3a-41ec9c3aef4f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_transaction',
	onMenu: 'Y',
	routeName: 'T2_transaction',
	urlRoute: '/T2_transaction',
	templateName: './templates/T2_transaction.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '4f7a13b4-6fd7-4037-a8f7-35cc2abb9e80',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'GSB_ui_component',
	onMenu: 'Y',
	routeName: 'GSB_ui_component',
	urlRoute: '/GSB_ui_component',
	templateName: './templates/GSB_ui_component.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '39eb31ad-d0bf-4da2-9cd7-b53c29ae18bf',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'input_function',
	onMenu: 'Y',
	routeName: 'input_function',
	urlRoute: '/input_function',
	templateName: './templates/input_function.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '73653db8-9bcc-4785-9d74-8f1effedb480',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_excees_claim',
	onMenu: 'Y',
	routeName: 'rl5_excees_claim',
	urlRoute: '/rl5_excees_claim',
	templateName: './templates/rl5_excees_claim.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'excess claim',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e061c084-48af-4ffc-b0d0-4bf31dfb8bcd',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_createTopicDrawer',
	onMenu: 'Y',
	routeName: 'kpi_createTopicDrawer',
	urlRoute: '/kpi_createTopicDrawer',
	templateName: './templates/kpi_createTopicDrawer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'db5c5358-1f58-4a2f-a07b-eab5db8619c0',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_other_benefits_hr',
	onMenu: 'Y',
	routeName: 'rl5_other_benefits_hr',
	urlRoute: '/rl5_other_benefits_hr',
	templateName: './templates/rl5_other_benefits_hr.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Other Benefits HR',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'eeb0830c-5cdc-43a2-9172-56dae31bbf5c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'TAReq',
	onMenu: 'Y',
	routeName: 'TAReq',
	urlRoute: '/TAReq',
	templateName: './templates/TAReq.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd2492c37-c500-4f49-a8d7-0b5006dab951',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RMProbationHistory',
	onMenu: 'Y',
	routeName: 'RMProbationHistory',
	urlRoute: '/RMProbationHistory',
	templateName: './templates/RMProbationHistory.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '2fbb0e23-2be9-48d3-be70-11c3be00cfd1',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Login_New',
	onMenu: 'Y',
	routeName: 'Login_New',
	urlRoute: '/Login_New',
	templateName: './templates/Login_New.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'LogIn',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'f8618384-2bfa-4e64-bb4e-4b5da705b715',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'career_kpi',
	onMenu: 'Y',
	routeName: 'career_kpi',
	urlRoute: '/career_kpi',
	templateName: './templates/career_kpi.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '27276ff0-6ee9-4919-94fc-9b21694d2067',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'navbar_gsb01',
	onMenu: 'Y',
	routeName: 'navbar_gsb01',
	urlRoute: '/navbar_gsb01',
	templateName: './templates/navbar_gsb01.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0e044a04-1b1f-435e-bf3d-8c388c843537',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'career_PB',
	onMenu: 'Y',
	routeName: 'career_PB',
	urlRoute: '/career_PB',
	templateName: './templates/career_PB.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'fe0ab6d5-8046-4573-a342-08196a2ffb3b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_04_scale_setup',
	onMenu: 'Y',
	routeName: 'RM004_04_scale_setup',
	urlRoute: '/RM004_04_scale_setup',
	templateName: './templates/RM004_04_scale_setup.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '2001dc7c-9140-46d9-9a6b-db6ff151e08d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Employee_certificate01',
	onMenu: 'Y',
	routeName: 'Employee_certificate01',
	urlRoute: '/Employee_certificate01',
	templateName: './templates/Employee_certificate01.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '5abf6afc-2230-4afc-9486-78e527d4a7e6',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Login_New_v2',
	onMenu: 'Y',
	routeName: 'Login_New_v2',
	urlRoute: '/Login_New_v2',
	templateName: './templates/Login_New_v2.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6f843616-1748-4909-abff-8a98def51800',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_Executive01',
	onMenu: 'Y',
	routeName: 'RM002_02_Executive01',
	urlRoute: '/RM002_02_Executive01',
	templateName: './templates/RM002_02_Executive01.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '675e193f-d554-4c9a-8a30-918ed303e576',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_Organization Manageme',
	onMenu: 'Y',
	routeName: 'RL5_Organization_Manageme',
	urlRoute: '/RL5_Organization_Manageme',
	templateName: './templates/RL5_Organization_Manageme.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'b8cb4434-a315-4e4f-8a78-f0308212fd22',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_career_careerpath',
	onMenu: 'Y',
	routeName: 'rl5_career_careerpath',
	urlRoute: '/rl5_career_careerpath',
	templateName: './templates/rl5_career_careerpath.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd2847a9e-5625-43e0-a519-3fa485c42b02',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_tax_deduct',
	onMenu: 'Y',
	routeName: 'T2_tax_deduct',
	urlRoute: '/T2_tax_deduct',
	templateName: './templates/T2_tax_deduct.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '40275b1d-cd6f-488d-bb6b-0f7268f08a86',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'final_result',
	onMenu: 'Y',
	routeName: 'final_result',
	urlRoute: '/final_result',
	templateName: './templates/final_result.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '46ff4a49-8f5a-4d67-ace7-23a95c343cd3',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'OTReq',
	onMenu: 'Y',
	routeName: 'OTReq',
	urlRoute: '/OTReq',
	templateName: './templates/OTReq.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'b9fad7f2-bbd5-4af5-b246-401ba2cc96a8',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5ViewActionPlan',
	onMenu: 'Y',
	routeName: 'RL5ViewActionPlan',
	urlRoute: '/RL5ViewActionPlan',
	templateName: './templates/RL5ViewActionPlan.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1fa7096a-fe28-428c-a84f-909a4f9de279',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_competency_manageeval',
	onMenu: 'Y',
	routeName: 'rl5_competency_manageeval',
	urlRoute: '/rl5_competency_manageeval',
	templateName: './templates/rl5_competency_manageeval.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Competency Appraisal (Management Evaluation)',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '5d1306fd-1b39-4da3-902b-e88b2cb80f30',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_settingProgressDrawer',
	onMenu: 'Y',
	routeName: 'kpi_settingProgressDrawer',
	urlRoute: '/kpi_settingProgressDrawer',
	templateName: './templates/kpi_settingProgressDrawer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1810852b-f8cf-4e64-a0f9-b8d7bd27b94c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'test_form',
	onMenu: 'Y',
	routeName: 'test_form',
	urlRoute: '/test_form',
	templateName: './templates/test_form.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'ff8148d0-bb79-4fe0-8026-b6cbc1552079',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'LoginCAS',
	onMenu: 'Y',
	routeName: 'LoginCAS',
	urlRoute: '/LoginCAS',
	templateName: './templates/LoginCAS.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6e91570f-cb6d-442d-a5cc-76891f07225d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM001_01_career',
	onMenu: 'Y',
	routeName: 'RM001_01_career',
	urlRoute: '/RM001_01_career',
	templateName: './templates/RM001_01_career.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1d78b65d-4972-4ca9-9962-8ef13b1d1dea',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_review_add_setting',
	onMenu: 'Y',
	routeName: 'peer_review_add_setting',
	urlRoute: '/peer_review_add_setting',
	templateName: './templates/peer_review_add_setting.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '28ae407e-fe39-4481-96ef-97bca0ee6c53',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PB_Detail',
	onMenu: 'Y',
	routeName: 'PB_Detail',
	urlRoute: '/PB_Detail',
	templateName: './templates/PB_Detail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '524ae5e6-c855-4d14-ae74-a1426672c129',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_other_inform',
	onMenu: 'Y',
	routeName: 'RM002_02_other_inform',
	urlRoute: '/RM002_02_other_inform',
	templateName: './templates/RM002_02_other_inform.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '01545d10-b86f-4ef7-b2ba-dd1fd310ce26',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'bidCourses_his',
	onMenu: 'Y',
	routeName: 'bidCourses_his',
	urlRoute: '/bidCourses_his',
	templateName: './templates/bidCourses_his.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'History Bidding Courses',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '406c558f-1d01-4754-a42d-2e127693143a',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_other_benefits_hr_New',
	onMenu: 'Y',
	routeName: 'rl5_other_benefits_hr_New',
	urlRoute: '/rl5_other_benefits_hr_New',
	templateName: './templates/rl5_other_benefits_hr_New.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'abb5ba9b-668b-4f8d-bb12-6a80c013f230',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KPI_Appraisal_Subordinate',
	onMenu: 'Y',
	routeName: 'KPI_Appraisal_Subordinate',
	urlRoute: '/KPI_Appraisal_Subordinate',
	templateName: './templates/KPI_Appraisal_Subordinate.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '65c77171-56f5-42ca-80e9-75015c9a9483',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_searchTopic-Copy(1)',
	onMenu: 'Y',
	routeName: 'kpi_searchTopic-Copy(1)',
	urlRoute: '/kpi_searchTopic-Copy(1)',
	templateName: './templates/kpi_searchTopic-Copy(1).html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '911ee920-fc4d-4df1-8195-baa34e55a44f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_evaluate',
	onMenu: 'Y',
	routeName: 'kpi_evaluate',
	urlRoute: '/kpi_evaluate',
	templateName: './templates/kpi_evaluate.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'KPI Appraisal (Self)',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'ccc362e3-0e3b-4947-a522-07a9d11cb6e4',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'talent_header',
	onMenu: 'Y',
	routeName: 'talent_header',
	urlRoute: '/talent_header',
	templateName: './templates/talent_header.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'aef4edff-5047-4fff-af60-51c39321eb58',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_coraporate2',
	onMenu: 'Y',
	routeName: 'kpi_coraporate2',
	urlRoute: '/kpi_coraporate2',
	templateName: './templates/kpi_coraporate2.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3c8d85bf-7993-46dd-a2f2-720b89b92ffa',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_time_adjust_approve',
	onMenu: 'Y',
	routeName: 'T2_time_adjust_approve',
	urlRoute: '/T2_time_adjust_approve',
	templateName: './templates/T2_time_adjust_approve.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '09f675ea-15b6-4cca-85c7-bf85bf0fa73d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_referral',
	onMenu: 'Y',
	routeName: 'RM002_02_referral',
	urlRoute: '/RM002_02_referral',
	templateName: './templates/RM002_02_referral.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'c47ed792-d6ac-486a-bd4b-7b1f929a6591',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RMProbation',
	onMenu: 'Y',
	routeName: 'RMProbation',
	urlRoute: '/RMProbation',
	templateName: './templates/RMProbation.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '8229a5f7-1f04-4b10-9c65-af45b2455a0e',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PR_monitorReviewer',
	onMenu: 'Y',
	routeName: 'PR_monitorReviewer',
	urlRoute: '/PR_monitorReviewer',
	templateName: './templates/PR_monitorReviewer.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e229cf76-8982-4d00-9c16-e21c5003cdbc',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_05',
	onMenu: 'Y',
	routeName: 'RM004_05',
	urlRoute: '/RM004_05',
	templateName: './templates/RM004_05.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'c3bd1b7e-ba1d-4538-bb74-e98cd0d02a5d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Open_Org_Node',
	onMenu: 'Y',
	routeName: 'Open_Org_Node',
	urlRoute: '/Open_Org_Node',
	templateName: './templates/Open_Org_Node.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e035e341-32b6-4e6f-9874-e4913d47320b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KP003_01',
	onMenu: 'Y',
	routeName: 'KP003_01',
	urlRoute: '/KP003_01',
	templateName: './templates/KP003_01.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '7b8900b1-7721-46ab-baa4-f93502d56094',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_03',
	onMenu: 'Y',
	routeName: 'RM002_03',
	urlRoute: '/RM002_03',
	templateName: './templates/RM002_03.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '729caeac-327f-4760-ad83-c383c1595580',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'select_questionnaire',
	onMenu: 'Y',
	routeName: 'select_questionnaire',
	urlRoute: '/select_questionnaire',
	templateName: './templates/select_questionnaire.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'c4f487e3-b2d3-40e0-b4b1-1df77da34c35',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_training_program',
	onMenu: 'Y',
	routeName: 'RM002_02_training_program',
	urlRoute: '/RM002_02_training_program',
	templateName: './templates/RM002_02_training_program.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '4cad4fec-428a-4769-8069-bfb55880a6c7',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T1_expense_request',
	onMenu: 'Y',
	routeName: 'T1_expense_request',
	urlRoute: '/T1_expense_request',
	templateName: './templates/T1_expense_request.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '83f4c40a-4a08-4847-b705-13883fb80eca',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'competencyReview',
	onMenu: 'Y',
	routeName: 'competencyReview',
	urlRoute: '/competencyreview',
	templateName: './templates/competencyReview.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'ade286fd-e0fb-4a8f-bfa6-7e5ac6dfebb4',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_05_expected_product',
	onMenu: 'Y',
	routeName: 'RM004_05_expected_product',
	urlRoute: '/RM004_05_expected_product',
	templateName: './templates/RM004_05_expected_product.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '99c53002-e738-4c70-8311-612ceb15dd71',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_select_assessor',
	onMenu: 'Y',
	routeName: 'peer_select_assessor',
	urlRoute: '/peer_select_assessor',
	templateName: './templates/peer_select_assessor.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'fca4343b-378e-493b-ac34-5d9376a3cf9e',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'close_assessment',
	onMenu: 'Y',
	routeName: 'close_assessment',
	urlRoute: '/close_assessment',
	templateName: './templates/close_assessment.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '7e79b9a8-afa1-4289-976a-fce7e1d7e2d9',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM001_01_header',
	onMenu: 'Y',
	routeName: 'RM001_01_header',
	urlRoute: '/RM001_01_header',
	templateName: './templates/RM001_01_header.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '8546c426-a708-4807-bdc7-283cbadde4b4',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'career_competency',
	onMenu: 'Y',
	routeName: 'career_competency',
	urlRoute: '/career_competency',
	templateName: './templates/career_competency.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '18fec4f8-96bd-4c1d-9120-fc90efd2285f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_Executive',
	onMenu: 'Y',
	routeName: 'RM002_02_Executive',
	urlRoute: '/RM002_02_Executive',
	templateName: './templates/RM002_02_Executive.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '32472c87-7787-4a16-8648-f630511f1bf8',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_06_evaluate',
	onMenu: 'Y',
	routeName: 'RM004_06_evaluate',
	urlRoute: '/RM004_06_evaluate',
	templateName: './templates/RM004_06_evaluate.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '61e43aae-03f4-417c-9aef-a91d2ff83556',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'peer_review_appraisal',
	onMenu: 'Y',
	routeName: 'peer_review_appraisal',
	urlRoute: '/peer_review_appraisal',
	templateName: './templates/peer_review_appraisal.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '075825d8-ce33-47af-bc4d-6dcc7a269cbc',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KPI_Appraisal',
	onMenu: 'Y',
	routeName: 'KPI_Appraisal',
	urlRoute: '/KPI_Appraisal',
	templateName: './templates/KPI_Appraisal.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'f5f7e87c-15e0-483d-8adb-ce4c71e44b43',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'ESS_GSB_1',
	onMenu: 'Y',
	routeName: 'ESS_GSB_1',
	urlRoute: '/ESS_GSB_1',
	templateName: './templates/ESS_GSB_1.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'ff6e6703-ab44-477d-80d5-ee6f36807f10',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RRL5SwapShiftHR',
	onMenu: 'Y',
	routeName: 'RRL5SwapShiftHR',
	urlRoute: '/RRL5SwapShiftHR',
	templateName: './templates/RRL5SwapShiftHR.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '881776a6-4dd6-4c6b-a4b3-3789aab1ec91',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_work_experience',
	onMenu: 'Y',
	routeName: 'RM002_02_work_experience',
	urlRoute: '/RM002_02_work_experience',
	templateName: './templates/RM002_02_work_experience.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'eae2b009-6719-49c9-988e-e56b0287bd19',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'OTReqHr',
	onMenu: 'Y',
	routeName: 'OTReqHr',
	urlRoute: '/OTReqHr',
	templateName: './templates/OTReqHr.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a9cf27af-68b7-4b5b-bc8c-66e5e866ea56',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_employee_certificate',
	onMenu: 'Y',
	routeName: 'rl5_employee_certificate',
	urlRoute: '/rl5_employee_certificate',
	templateName: './templates/rl5_employee_certificate.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd503fd41-b70c-4a9c-988f-fd8fcea70484',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM003_07',
	onMenu: 'Y',
	routeName: 'RM003_07',
	urlRoute: '/RM003_07',
	templateName: './templates/RM003_07.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'ce1f5835-0763-4eff-902d-1a913a4d1c85',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Form_Payslip',
	onMenu: 'Y',
	routeName: 'Form_Payslip',
	urlRoute: '/Form_Payslip',
	templateName: './templates/Form_Payslip.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd5a3dd5c-1d03-477a-a80d-62e26d3c1ac7',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_teamBenefitOTP',
	onMenu: 'Y',
	routeName: 'rl5_teamBenefitOTP',
	urlRoute: '/rl5_teamBenefitOTP',
	templateName: './templates/rl5_teamBenefitOTP.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a38ce4cb-ec84-4b07-a8f8-2eb8bf47ec9b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_management',
	onMenu: 'Y',
	routeName: 'kpi_management',
	urlRoute: '/kpi_management',
	templateName: './templates/kpi_management.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '06256a19-ac30-4fcc-8bfe-f5ee1a49846c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_Validate_Field',
	onMenu: 'Y',
	routeName: 'RM002_02_Validate_Field',
	urlRoute: '/RM002_02_Validate_Field',
	templateName: './templates/RM002_02_Validate_Field.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'f4e9dd71-6db3-4e93-8d49-c6d01b66e8b9',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KLPB_management',
	onMenu: 'Y',
	routeName: 'KLPB_management',
	urlRoute: '/KLPB_management',
	templateName: './templates/KLPB_management.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '7298f34a-6802-4e19-9dfc-be87cc135796',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM001_01_job_family',
	onMenu: 'Y',
	routeName: 'RM001_01_job_family',
	urlRoute: '/RM001_01_job_family',
	templateName: './templates/RM001_01_job_family.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6d4573d3-d4f5-4fe9-b255-188809818345',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Application',
	onMenu: 'Y',
	routeName: 'Application',
	urlRoute: '/Application',
	templateName: './templates/Application.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '67431fcb-abf4-4cf6-b075-b8d592a691e8',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM001_01_job_reward',
	onMenu: 'Y',
	routeName: 'RM001_01_job_reward',
	urlRoute: '/RM001_01_job_reward',
	templateName: './templates/RM001_01_job_reward.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '01781dfc-549d-42da-8bff-6dbb33b3f5d6',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'manpower_2',
	onMenu: 'Y',
	routeName: 'manpower_2',
	urlRoute: '/manpower_2',
	templateName: './templates/manpower_2.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '7926ffe3-f044-46a3-9d5a-7f9cdf58d230',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'send_an_assessment',
	onMenu: 'Y',
	routeName: 'send_an_assessment',
	urlRoute: '/send_an_assessment',
	templateName: './templates/send_an_assessment.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1e772e33-cdf5-4fb3-83f5-91ce3ef6a901',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_Address',
	onMenu: 'Y',
	routeName: 'RM002_02_Address',
	urlRoute: '/RM002_02_Address',
	templateName: './templates/RM002_02_Address.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'c0343a3f-3825-4fc8-b6a1-1be4dcd61bf3',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_selfReview',
	onMenu: 'Y',
	routeName: 'kpi_selfReview',
	urlRoute: '/kpi_selfReview',
	templateName: './templates/kpi_selfReview.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '324fd783-5b5f-4ff8-8695-a4a6ad7542fe',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Form_Visa',
	onMenu: 'Y',
	routeName: 'Form_Visa',
	urlRoute: '/Form_Visa',
	templateName: './templates/Form_Visa.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '37516278-dc92-42d7-bcc9-228e4dad2eca',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Login_ADFS_Deep',
	onMenu: 'Y',
	routeName: 'Login_ADFS_Deep',
	urlRoute: '/Login_ADFS_Deep',
	templateName: './templates/Login_ADFS_Deep.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0806593a-8af0-49f4-ab5a-d4566add2b0b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'employee_certificate_ap',
	onMenu: 'Y',
	routeName: 'employee_certificate_ap',
	urlRoute: '/employee_certificate_ap',
	templateName: './templates/employee_certificate_ap.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '15e48ab1-15a7-4cef-a704-4b087ff01b69',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM001_01_career_manage',
	onMenu: 'Y',
	routeName: 'RM001_01_career_manage',
	urlRoute: '/RM001_01_career_manage',
	templateName: './templates/RM001_01_career_manage.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '32566991-60e0-42f0-ae2c-e75901b18635',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'user_function',
	onMenu: 'Y',
	routeName: 'user_function',
	urlRoute: '/user_function',
	templateName: './templates/user_function.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '0a044b78-11df-4688-bff1-307d33c8b050',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_individual',
	onMenu: 'Y',
	routeName: 'kpi_individual',
	urlRoute: '/kpi_individual',
	templateName: './templates/kpi_individual.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'ab116e5f-097e-41ca-8b0d-fc58b3617af4',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_10',
	onMenu: 'Y',
	routeName: 'RM004_10',
	urlRoute: '/RM004_10',
	templateName: './templates/RM004_10.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '74c6b035-0641-412b-9f1b-85738edbd095',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'MASTER_SET_UP',
	onMenu: 'Y',
	routeName: 'MASTER_SET_UP',
	urlRoute: '/MASTER_SET_UP',
	templateName: './templates/MASTER_SET_UP.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '38c91ca6-1a3d-451a-a1d1-9bdf7919318b',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_career_careerpath',
	onMenu: 'Y',
	routeName: 'rl5_career_careerpath',
	urlRoute: '/rl5_career_careerpath',
	templateName: './templates/rl5_career_careerpath.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '298d4bfe-cf00-4077-8249-887adafbd444',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'training_request_add',
	onMenu: 'Y',
	routeName: 'training_request_add',
	urlRoute: '/training_request_add',
	templateName: './templates/training_request_add.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'e38a3f27-007e-4895-9d6a-411cedfa0de9',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_06',
	onMenu: 'Y',
	routeName: 'RM004_06',
	urlRoute: '/RM004_06',
	templateName: './templates/RM004_06.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'c4b84919-69d7-423e-9393-9f22af9d364c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_appraisal_manage_eval',
	onMenu: 'Y',
	routeName: 'kpi_appraisal_manage_eval',
	urlRoute: '/kpi_appraisal_manage_eval',
	templateName: './templates/kpi_appraisal_manage_eval.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '6edfacde-c4c0-4dbf-ad27-ce3fbb25ce44',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Em_Certificate_AppDetail',
	onMenu: 'Y',
	routeName: 'Em_Certificate_AppDetail',
	urlRoute: '/Em_Certificate_AppDetail',
	templateName: './templates/Em_Certificate_AppDetail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '8bea9967-93af-497b-afdd-a150469945d5',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PB_Setup',
	onMenu: 'Y',
	routeName: 'PB_Setup',
	urlRoute: '/PB_Setup',
	templateName: './templates/PB_Setup.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Performance Set up',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '102507f0-59fb-4747-82af-5c48aea61a02',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'rl5_teamBenefitReq',
	onMenu: 'Y',
	routeName: 'rl5_teamBenefitReq',
	urlRoute: '/rl5_teamBenefitReq',
	templateName: './templates/rl5_teamBenefitReq.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'aa9f5c6d-bbaf-4d68-8ff9-5db5f06ab726',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'Org_test',
	onMenu: 'Y',
	routeName: 'Org_test',
	urlRoute: '/Org_test',
	templateName: './templates/Org_test.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'aa21a194-bb6f-4571-b2ef-21f3c627e20a',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_consent',
	onMenu: 'Y',
	routeName: 'RM002_02_consent',
	urlRoute: '/RM002_02_consent',
	templateName: './templates/RM002_02_consent.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '8b795251-403e-411b-bda0-12c739b29b9a',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T2_Tax_Deduction_Appr',
	onMenu: 'Y',
	routeName: 'T2_Tax_Deduction_Appr',
	urlRoute: '/T2_Tax_Deduction_Appr',
	templateName: './templates/T2_Tax_Deduction_Appr.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'c9ba8fa8-fd1f-46bd-9ff6-09468f670cb5',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'KPI_Appraisal_Management',
	onMenu: 'Y',
	routeName: 'KPI_Appraisal_Management',
	urlRoute: '/KPI_Appraisal_Management',
	templateName: './templates/KPI_Appraisal_Management.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '82c95acf-6b34-4767-b367-0f32c4180a2c',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM_job_opening_internet',
	onMenu: 'Y',
	routeName: 'RM_job_opening_internet',
	urlRoute: '/RM_job_opening_internet',
	templateName: './templates/RM_job_opening_internet.html',
	isLogin: 'N',
	isHome: 'Y',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '587eccbf-3351-41d1-8baa-b81963919d02',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02',
	onMenu: 'Y',
	routeName: 'RM002_02',
	urlRoute: '/RM002_02',
	templateName: './templates/RM002_02.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'bddcc467-7744-4895-b9b0-5007d80ad4db',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM002_02_attach_file',
	onMenu: 'Y',
	routeName: 'RM002_02_attach_file',
	urlRoute: '/RM002_02_attach_file',
	templateName: './templates/RM002_02_attach_file.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '623652d4-1138-4916-9570-43ed8bca7863',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_08',
	onMenu: 'Y',
	routeName: 'RM004_08',
	urlRoute: '/RM004_08',
	templateName: './templates/RM004_08.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '377bdc77-7b45-464e-a5a2-d1455b3c63ab',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'benefit_new',
	onMenu: 'Y',
	routeName: 'benefit_new',
	urlRoute: '/benefit_new',
	templateName: './templates/benefit_new.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Benefit',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '3f994049-f7df-4544-9f52-e2d068b10528',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_review',
	onMenu: 'Y',
	routeName: 'kpi_review',
	urlRoute: '/kpi_review',
	templateName: './templates/kpi_review.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '7e29d37e-d4e8-4e53-819b-09f34bf8969f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_functional',
	onMenu: 'Y',
	routeName: 'kpi_functional',
	urlRoute: '/kpi_functional',
	templateName: './templates/kpi_functional.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '7fa5ed1b-f7ef-49a1-bd63-3fe28065a8ea',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'T1_confirm_claim_payroll2',
	onMenu: 'Y',
	routeName: 'T1_confirm_claim_payroll2',
	urlRoute: '/T1_confirm_claim_payroll2',
	templateName: './templates/T1_confirm_claim_payroll2.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: 'Confirm Claim to Payroll',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'a63afba7-a5e6-40e5-acd4-c1c9b55c4d72',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'kpi_searchTopic',
	onMenu: 'Y',
	routeName: 'kpi_searchTopic',
	urlRoute: '/kpi_searchTopic',
	templateName: './templates/kpi_searchTopic.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '4d6cd745-ebb6-4db7-956a-3063aab2645d',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'ess_gbs_new',
	onMenu: 'Y',
	routeName: 'ess_gbs_new',
	urlRoute: '/ess_gbs_new',
	templateName: './templates/ess_gbs_new.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: 'd4456ce8-8c6e-4e08-a00c-4acb07c33088',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RM004_04',
	onMenu: 'Y',
	routeName: 'RM004_04',
	urlRoute: '/RM004_04',
	templateName: './templates/RM004_04.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '1a1cabd0-1cc0-4667-8207-d62cd289b5cb',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'PB_employeeDetail',
	onMenu: 'Y',
	routeName: 'PB_employeeDetail',
	urlRoute: '/PB_employeeDetail',
	templateName: './templates/PB_employeeDetail.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
},{
	id: '45d44ea9-95b2-4fe9-9ef4-eaee70f7ac1f',
	appId: '595fdf16-97ce-4f23-a87b-6c8a62193f04',
	menuName: 'RL5_action_plan',
	onMenu: 'Y',
	routeName: 'RL5_action_plan',
	urlRoute: '/RL5_action_plan',
	templateName: './templates/RL5_action_plan.html',
	isLogin: 'N',
	isHome: 'N',
	pageTitle: '',
	memberOnly: 'N',
	accessRoles: []
}];
